export const languageData = {
    "languages": [
        { "language_code": "en", "language_name": "English" },
        { "language_code": "zh", "language_name": "Chinese" },
        { "language_code": "es", "language_name": "Spanish" },
        { "language_code": "hi", "language_name": "Hindi" },
        { "language_code": "pt", "language_name": "Portuguese" },
        { "language_code": "fr", "language_name": "French" },
        { "language_code": "de", "language_name": "German" },
        { "language_code": "ja", "language_name": "Japanese" },
        { "language_code": "ar", "language_name": "Arabic" },
        { "language_code": "ru", "language_name": "Russian" },
        { "language_code": "ko", "language_name": "Korean" },
        { "language_code": "id", "language_name": "Indonesian" },
        { "language_code": "it", "language_name": "Italian" },
        { "language_code": "nl", "language_name": "Dutch" },
        { "language_code": "tr", "language_name": "Turkish" },
        { "language_code": "pl", "language_name": "Polish" },
        { "language_code": "sv", "language_name": "Swedish" },
        { "language_code": "tl", "language_name": "Tagalog" },
        { "language_code": "ms", "language_name": "Malay" },
        { "language_code": "ro", "language_name": "Romanian" },
        { "language_code": "uk", "language_name": "Ukrainian" },
        { "language_code": "el", "language_name": "Greek" },
        { "language_code": "cs", "language_name": "Czech" },
        { "language_code": "da", "language_name": "Danish" },
        { "language_code": "fi", "language_name": "Finnish" },
        { "language_code": "bg", "language_name": "Bulgarian" },
        { "language_code": "hr", "language_name": "Croatian" },
        { "language_code": "sk", "language_name": "Slovak" },
        { "language_code": "ta", "language_name": "Tamil" }
    ]
}

export const supportedLanguages = {
    "languages": [
        { "language_code": "en", "language_name": "English" },
        { "language_code": "tr", "language_name": "Turkish" },
        { "language_code": "de", "language_name": "German" },
        { "language_code": "fr", "language_name": "French" },
        { "language_code": "zh", "language_name": "Chinese" },
        { "language_code": "es", "language_name": "Spanish" },
        { "language_code": "hi", "language_name": "Hindi" },
        { "language_code": "ar", "language_name": "Arabic" },
        { "language_code": "pt", "language_name": "Portuguese" },
        { "language_code": "bn", "language_name": "Bengali" },
        { "language_code": "ru", "language_name": "Russian" },
        { "language_code": "ja", "language_name": "Japanese" },
        { "language_code": "it", "language_name": "Italian" },
        { "language_code": "ko", "language_name": "Korean" },
        { "language_code": "vi", "language_name": "Vietnamese" },
        { "language_code": "pl", "language_name": "Polish" },
        { "language_code": "uk", "language_name": "Ukrainian" },
        { "language_code": "nl", "language_name": "Dutch" },
        { "language_code": "el", "language_name": "Greek" },
        { "language_code": "sv", "language_name": "Swedish" },
        { "language_code": "cs", "language_name": "Czech" },
        { "language_code": "hu", "language_name": "Hungarian" },
        { "language_code": "fi", "language_name": "Finnish" },
        { "language_code": "no", "language_name": "Norwegian" },
        { "language_code": "da", "language_name": "Danish" },
        { "language_code": "he", "language_name": "Hebrew" },
        { "language_code": "fa", "language_name": "Persian" },
        { "language_code": "ta", "language_name": "Tamil" },
        { "language_code": "ur", "language_name": "Urdu" },
        { "language_code": "th", "language_name": "Thai" },
        { "language_code": "id", "language_name": "Indonesian" },
        { "language_code": "ms", "language_name": "Malay" },
        { "language_code": "tl", "language_name": "Tagalog" },
        { "language_code": "ro", "language_name": "Romanian" },
        { "language_code": "bg", "language_name": "Bulgarian" },
        { "language_code": "sr", "language_name": "Serbian" },
        { "language_code": "hr", "language_name": "Croatian" },
        { "language_code": "sk", "language_name": "Slovak" },
        { "language_code": "sl", "language_name": "Slovenian" },
        { "language_code": "lt", "language_name": "Lithuanian" },
        { "language_code": "lv", "language_name": "Latvian" },
        { "language_code": "et", "language_name": "Estonian" },
        { "language_code": "mk", "language_name": "Macedonian" },
        { "language_code": "be", "language_name": "Belarusian" },
        { "language_code": "ka", "language_name": "Georgian" },
        { "language_code": "hy", "language_name": "Armenian" },
        { "language_code": "az", "language_name": "Azerbaijani" },
        { "language_code": "af", "language_name": "Afrikaans" },
        { "language_code": "bs", "language_name": "Bosnian" },
        { "language_code": "ca", "language_name": "Catalan" },
        { "language_code": "gl", "language_name": "Galician" },
        { "language_code": "is", "language_name": "Icelandic" },
        { "language_code": "sw", "language_name": "Swahili" },
        { "language_code": "ne", "language_name": "Nepali" },
        { "language_code": "kn", "language_name": "Kannada" },
        { "language_code": "kk", "language_name": "Kazakh" },
        { "language_code": "mr", "language_name": "Marathi" },
        { "language_code": "mi", "language_name": "Maori" }
    ]
}
